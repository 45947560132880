
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import {
  Big6Math,
  calcEstExecutionPrice,
  calcInterfaceFee,
  calcLeverage,
  calcLiquidationPrice,
  calcMakerStats,
  calcTradeFee,
  formatBig6USDPrice,
  formatBig6Percent,
  MarketSnapshot,
  PositionSide,
  UserMarketSnapshot,
} from '@perennial/sdk'

import { CustomTooltip } from "../../../common";
import { useMarketContext } from "../../../../contexts/marketContext";
import { usePerpetualsChainId } from "../../../../hooks/network";
import { calcLpUtilization, getMakerExposure } from "../../../../utils/positionUtils";
import { useMarket7dData } from "../../../../hooks/markets2/graph";
import { capitalize } from "../../../../utils/utils";
import { TradingFees } from "./TradingFees";
import { OrderExecutionDeposit } from "../../constants";
import { useLivePriceContext } from "../../../../contexts/livePriceContext";
import { interfaceFeeBps } from "../../../../constants/network";


type FeesProps = {
  isNewPosition: boolean
  isProInterface: boolean
  market: MarketSnapshot
  positionDetails?: UserMarketSnapshot
  positionDelta: {
    collateralDelta: bigint
    positionDelta: bigint
    fullClose?: boolean
  }
  leverage?: number
  isLimitOrder: boolean
  limitOrderPrice?: bigint
}


export const TradeMetrics = ({
  isNewPosition,
  isProInterface,
  market,
  positionDelta,
  positionDetails,
  isLimitOrder,
  limitOrderPrice
}: FeesProps) => {
  const { t } = useTranslation()
  const [showAprDetail, setShowAprDetail] = useState(false)
  const chainId = usePerpetualsChainId()
  const { data: market7dData } = useMarket7dData()
  const { marketMetadata, isMaker, orderDirection, selectedMarketSnapshot2, selectedMarket, selectedMakerMarket } = useMarketContext()
  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket
  
  const livePrices = useLivePriceContext()
  const latestPrice = selectedMarketSnapshot2 ? selectedMarketSnapshot2.global.latestPrice : 0n
  const oraclePrice = livePrices[currentMarket]?.price ?? latestPrice
  
  const { parameter: { settlementFee }, global: { latestPrice: price } } = market
  const currentPositionSide = isNewPosition ? orderDirection : (positionDetails?.nextSide ?? orderDirection)

  const tradingFee = selectedMarketSnapshot2 ?
    calcTradeFee({
      positionDelta: positionDelta.positionDelta,
      isMaker,
      marketSnapshot: selectedMarketSnapshot2,
      direction: currentPositionSide
    }) : { tradeFee: 0n, feeBasisPoints: 0n }

  const newPosition = positionDelta.positionDelta + (positionDetails?.nextMagnitude ?? 0n)
  const newCollateral = positionDelta.fullClose
    ? 0n
    : positionDelta.collateralDelta + (positionDetails?.local.collateral ?? 0n)
  const newLeverage = calcLeverage(price, newPosition, newCollateral)

  const lpUtilization = calcLpUtilization(selectedMarketSnapshot2)
  const makerExposure = getMakerExposure(lpUtilization?.lpUtilization, newLeverage)  

  const makerStats = calcMakerStats({
    funding: market7dData?.[market.market]?.makerAccumulation.funding ?? 0n,
    interest: market7dData?.[market.market]?.makerAccumulation.interest ?? 0n,
    positionFee: market7dData?.[market.market]?.makerAccumulation.positionFee ?? 0n,
    positionSize: newPosition,
    collateral: newCollateral,
  })

  const liquidationPrices = calcLiquidationPrice({
    marketSnapshot: market,
    collateral: newCollateral,
    position: newPosition,
    limitPrice: limitOrderPrice,
  })

  const hasPositionChange = positionDelta.positionDelta !== 0n
  const interfaceFee = !marketMetadata.isCryptexIndex ?
    calcInterfaceFee({
      positionStatus: positionDetails?.status,
      latestPrice: isLimitOrder && limitOrderPrice ? limitOrderPrice : market.global.latestPrice,
      chainId,
      positionDelta: positionDelta.positionDelta,
      side: isMaker ? PositionSide.maker : orderDirection,
      referrerInterfaceFeeDiscount: 0n,
      referrerInterfaceFeeShare: 0n,
      interfaceFeeBps: interfaceFeeBps[chainId].feeAmount[isMaker ? PositionSide.maker : orderDirection],
    }) : undefined;
  // const nonImpactTradeFee = isMaker ? 0n : Big6Math.mul(market.parameter.positionFee, tradingFee.total)
  const nonImpactTradeFee = isMaker ? 0n : Big6Math.mul(market.parameter.positionFee, tradingFee.tradeFee)
  const estExecutionPrice = !Big6Math.isZero(positionDelta.positionDelta) && selectedMarketSnapshot2
    ? calcEstExecutionPrice({
        positionDelta: Big6Math.abs(positionDelta.positionDelta),  
        oraclePrice,  
        orderDirection,
        marketSnapshot: selectedMarketSnapshot2,
      })
    : { total: oraclePrice, priceImpact: 0n, priceImpactPercentage: 0n }
  const close = positionDelta.positionDelta < 0n

  return (
    <div className="fees">
      <div className="fees-container">
        {!isProInterface && (
          <div className="liquidation">
            <CustomTooltip
              id="exliq-price"
              msg={t("tooltip.liquidation-price")}
              showIcon={true}
              iconOnLeft={true}
              iconSize={13}
            >
              <h6 className="bold margin-left">{t("liquidation-price")}:</h6>
            </CustomTooltip>
            <NumericFormat
              className="balance-usd bold"
              value={formatBig6USDPrice(
                currentPositionSide === PositionSide.long ? liquidationPrices.long : liquidationPrices.short,
              )}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={marketMetadata.displayDecimals}
            />
          </div>
        )}  
        {isProInterface && (
          <>
            <div className="row-value">
              <div className="value-group">
                <div className="value-group-total">
                  <div className="value-item-desc">
                    <CustomTooltip
                      id="ol-fee1"
                      msg={t("tooltip.total-apr")}
                      showIcon={true}
                      iconOnLeft={true}
                      iconSize={13}
                    >
                      <h6 className="bold margin-left">
                        {t("total-apr")}
                      </h6>
                    </CustomTooltip>
                    <Button
                      variant="secondary small underline"
                      onClick={() => setShowAprDetail(!showAprDetail)}
                    >
                      {showAprDetail ? `(${t("hide")})` : `(${t("detail")}`}
                    </Button>
                    <h6>:</h6>
                  </div>  
                  <NumericFormat
                    className="number bold"
                    value={
                      formatBig6Percent(makerStats.fundingAPR + makerStats.interestAPR + makerStats.positionFeeAPR, { numDecimals: 4 })
                    }
                    displayType="text"
                    thousandSeparator=","
                    decimalScale={4}
                    suffix="%"
                  />
                </div>
                <div className={"value-group-detail ".concat(showAprDetail ? "show" : "hide")}>
                  <div className="value-item">
                    <h6>{t("funding-fee-apr")}:</h6>
                    <NumericFormat
                      className="number bold"
                      value={formatBig6Percent(makerStats.fundingAPR + makerStats.interestAPR)}
                      displayType="text"
                      thousandSeparator=","
                      suffix="%"
                      decimalScale={4}
                    />
                  </div>
                  <div className="value-item">
                    <h6>{t("trading-fee-apr")}:</h6>
                    <NumericFormat
                      className="number bold"
                      value={formatBig6Percent(makerStats.positionFeeAPR)}
                      displayType="text"
                      thousandSeparator=","
                      suffix="%"
                      decimalScale={4}
                    />
                  </div>
                </div>
              </div>                
            </div>
            <div className="row-value">
              <CustomTooltip
                id="ol-fee1"
                msg={t("tooltip.exposure")}
                showIcon={true}
                iconOnLeft={true}
                iconSize={13}
              >
                <h6 className="bold margin-left">{t("exposure")}:</h6>
              </CustomTooltip>
              <NumericFormat
                className="balance-usd bold"
                value={formatBig6Percent(makerExposure ?? 0n)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                suffix={"% ".concat(capitalize(lpUtilization?.exposureSide || ""))}
              />
            </div>
          </>  
        )}
        {!isProInterface && (
          <>
            <div className="row-value">
              <CustomTooltip
                id="ol-fee2"
                msg={t("tooltip.entry-price", { value: !!close ? 'exit' : 'entry' })}
                showIcon={true}
                iconOnLeft={true}
                iconSize={13}
              >
                <h6 className="bold margin-left">
                  {!!close ? t("est-exit-price") : t("est-entry-price")}:
                </h6>
              </CustomTooltip>  
              <NumericFormat
                className="balance-usd bold"
                value={
                  isLimitOrder ? formatBig6USDPrice(limitOrderPrice) : formatBig6USDPrice(estExecutionPrice.total)
                }
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
            </div>
            <div className="row-value">
              <CustomTooltip
                id="tl-price-impract"
                msg={t("tooltip.price-impact")}
                showIcon={true}
                iconOnLeft={true}
                iconSize={13}
              >
                <h6 className="bold margin-left">
                  {t("est-price-impact")}
                </h6>
              </CustomTooltip>  
              <NumericFormat
                className={"balance-usd bold ".concat(estExecutionPrice.priceImpact === 0n ? "text-green" : "text-red")}
                value={formatBig6Percent(estExecutionPrice.priceImpactPercentage, { numDecimals: 4 })}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                suffix="%"
              />
            </div>
          </>  
        )}
        {isProInterface && (
          <div className="row-value">
            <CustomTooltip
              id="exliq-price"
              msg={t("tooltip.ls-liq-price")}
              showIcon={true}
              iconOnLeft={true}
              iconSize={13}
            >
              <h6 className="bold margin-left">L/S {t("liq-price")}:</h6>
            </CustomTooltip>
            <div>
              <NumericFormat
                className="balance-usd bold"
                value={formatBig6USDPrice(liquidationPrices.long)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
              <span className="bold">{" / "}</span>
              <NumericFormat
                className="balance-usd bold"
                value={formatBig6USDPrice(liquidationPrices.short)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
            </div>  
          </div>
        )}
        {isLimitOrder && (
          <div className="row-value">
            <CustomTooltip
              id="exliq-price"
              msg={t("tooltip.order-execution-deposit")}
              showIcon={true}
              iconOnLeft={true}
              iconSize={13}
            >
              <h6 className="bold margin-left">{t("order-execution-deposit")}:</h6>
            </CustomTooltip>
            <NumericFormat
              className="balance-usd bold"
              value={Big6Math.toFloatString(OrderExecutionDeposit)}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              prefix="$"
            />
          </div>
        )}
        <TradingFees
          isMaker={isProInterface}
          hasPositionChange={hasPositionChange}
          // totalFee={tradingFee.total}
          totalFee={tradingFee.tradeFee}
          settlementFee={settlementFee}
          nonImpactTradeFee={nonImpactTradeFee}
          interfaceFee={interfaceFee?.interfaceFee || 0n}
          isLimitOrder={isLimitOrder}
        />
      </div>
    </div>  
  )    
};
