import React, { useMemo } from "react"
import { Dropdown, Image, Row, Stack } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { formatBig6Percent, PerennialVaultType, SupportedAsset, VaultMetadata, VaultSnapshot } from "@perennial/sdk"
import ReactGA from "react-ga4";

import { useCryptexProductsContext, useVaultContext } from "../../../contexts"
import { usePerpetualsChainId } from "../../../hooks/network"
import { MarketMetadata } from "../../../constants/markets"
import { useVault7dMarketData } from "../../../hooks/vaults2"
import { useExposureAndFunding } from "../hooks"
import { TcapMarketItem, TcapVaultMarketToggle } from "../../../v1/components/TcapMarket"
import { TCAP_TOKEN } from "../../../v1/utils/constants"
import { CryptexProducts } from "../../../constants/network"


export const VaultSelector = () => {
  const { t } = useTranslation()
  const cryptexProducts = useCryptexProductsContext()
  const chainId = usePerpetualsChainId()
  const { setSelectedVault, vaultSnapshots, selectedVault } = useVaultContext()
  const vaultMarketData = useVault7dMarketData()
  const selectedVaultSnapshot = useMemo(() => {
    if (vaultSnapshots && selectedVault) {
      return vaultSnapshots?.vault?.[selectedVault]
    }
  }, [vaultSnapshots, selectedVault])

  const selectedVaultMetadata = VaultMetadata[chainId]?.[selectedVault as PerennialVaultType]
  const exposureData = useExposureAndFunding({
    chainId,
    vault: selectedVaultSnapshot,
    marketData: vaultMarketData.find((v) => v.data?.vault.vaultAddress === selectedVaultSnapshot?.vault)?.data?.marketData,
  })

  const selectedVaultApr = (exposureData?.totalFeeAPR || 0n) + (exposureData?.totalFundingAPR || 0n)

  return (
    <Dropdown
      className="vaults-dropdown"
      onSelect={(eventKey) => {
        ReactGA.event({
          category: "Vautls",
          action: "Vault Selected",
          label: `${eventKey !== TCAP_TOKEN.key ? selectedVaultMetadata?.name : "TCAP"} vault selected`,
        });
        if (cryptexProducts.isPerpetuals() && eventKey !== TCAP_TOKEN.key) {
          setSelectedVault(eventKey as PerennialVaultType);
        } else if (cryptexProducts.isPerpetuals() && eventKey === TCAP_TOKEN.key) {
          cryptexProducts.setCurrentProduct(CryptexProducts.PerpetualsV1);
        } else if (cryptexProducts.isPerpetualsV1() && eventKey !== TCAP_TOKEN.key) {
          cryptexProducts.setCurrentProduct(CryptexProducts.Perpetuals);
        }
      }}
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {cryptexProducts.isPerpetualsV1() ? (
          <TcapVaultMarketToggle />
        ): (
          <div className="dropdown-toggle-container">
            <div className="toggle-section desc-box">
              <div className="vault-assets">
                {selectedVaultSnapshot && selectedVaultSnapshot?.markets.map((market) => {
                  if (selectedVault === PerennialVaultType.alpha && market.market === SupportedAsset.btc) {
                    return null
                  }
                  return <Image className="token-icon margin-right" src={MarketMetadata[market.market].icon} height={25} width={25} />
                })}
              </div>
              <div className="market-desc">
                <h6 className="title">
                  {selectedVaultMetadata?.name}
                </h6>
                <span className="caption">{t("select-vault")}</span>
              </div>
            </div>
            <div className="vertical-divisor" />
            <div className="toggle-section apr-box">
              <span className="number text-green">{formatBig6Percent(selectedVaultApr)}</span>
            </div>
          </div>    
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <>
          <Row className="dropdown-menu-header">
            <span className="small text-muted">{t("vault")}</span>
            <span className="small text-muted text-right">{t("apr")}</span>
          </Row>    
          {Object.values(vaultSnapshots?.vault ?? {}).map((vaultSnapshot, index) => {
            return (
              <VaultItem key={index.toString()} vaultSnapshot={vaultSnapshot} />
            )
          })}
          <TcapMarketItem isVault />
        </>  
      </Dropdown.Menu>
    </Dropdown>
  )
}


const VaultItem = ({ vaultSnapshot } : { vaultSnapshot: VaultSnapshot }) => {
  const chainId = usePerpetualsChainId()
  const { vaultType, vault } = vaultSnapshot
  const metadata = VaultMetadata[chainId]?.[vaultType as PerennialVaultType]
  const vaultMarketData = useVault7dMarketData()
  const exposureData = useExposureAndFunding({
    chainId,
    vault: vaultSnapshot,
    marketData: vaultMarketData.find((v) => v.data?.vault.vaultAddress === vault)?.data?.marketData,
  })
  const vaultApr = (exposureData?.totalFeeAPR || 0n) + (exposureData?.totalFundingAPR || 0n)

  if (!metadata) return <></>

  return (
    <Dropdown.Item key={vault} eventKey={vaultType}>
      <Stack className="left" direction="horizontal">
        {vaultSnapshot.markets.map((market, index) => {
          if (vaultType === PerennialVaultType.alpha && market.market === SupportedAsset.btc) {
            return null
          }

          return (
            <Image
              key={`img-${index}-${vault}`}
              className="token-icon margin-right"
              src={MarketMetadata[market.market].icon}
              height={20}
              width={20}
            />
          )
        })}
        <>{metadata.name}</>
      </Stack>
      <Stack className="right">
        <span className="number text-green">
          {formatBig6Percent(vaultApr)}
        </span>
      </Stack>
    </Dropdown.Item>
  )
}