import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Address, zeroAddress } from 'viem'
import { usePublicClient, useWalletClient } from 'wagmi'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'


import { metamaskTxRejectedError } from '../../constants/network'
import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'

import { useAddress, useChainActions, usePerpetualsChainId } from '../network'
import { useMarketOracles2, useMarketSnapshots2 } from './chain'
import { errorNotification, useTransactionToasts } from '../../components/common'


export const useMarketTransactions2 = (productAddress: Address) => {
  const sdk = usePerennialSDKContext()
  const { data: walletClient } = useWalletClient()
  const { address } = useAddress()
  const addRecentTransaction = useAddRecentTransaction()
  const toasts = useTransactionToasts()
  const copy = useMarketTransactionCopy()
  const { onSwitchChain } = useChainActions()
  const { data: marketOracles } = useMarketOracles2()
  const { data: marketSnapshots } = useMarketSnapshots2()
  
  return {
    getUSDCAllowance: async () => sdk.operator.read.usdcAllowance({ address: address ?? zeroAddress }),
    onPlaceOrder: async (
      args: Omit<Parameters<typeof sdk.markets.write.placeOrder>[0], 'marketAddress' | 'address'>,
      onError?: (err: any) => void,
    ) => {
      if (!(await onSwitchChain())) return

      try {
        return sdk.markets.write.placeOrder({
          ...args,
          address: address ?? zeroAddress,
          marketAddress: productAddress,
          marketSnapshots,
          marketOracles,
        })
      } catch (err: any) {
        onError?.(err)
      }
    },
    onApproveUSDC: async (args: Parameters<typeof sdk.operator.write.approveUSDC>[0]) => {
      if (!(await onSwitchChain())) return

      const hash = await sdk.operator.write.approveUSDC(args)
      await toasts.waitForTransactionAlert(hash, { successMsg: copy.approveUSDC })
      addRecentTransaction({
        hash,
        description: "Approved USDC for market",
      })
      const newAllowance = await sdk.operator.read.usdcAllowance({ address: address ?? zeroAddress })

      return { hash, newAllowance }
    },
    onModifyPosition: async (
      args: Omit<Parameters<typeof sdk.markets.write.modifyPosition>[0], 'marketAddress' | 'address'>,
      onError?: (err: any) => void,
    ) => {
      if (!(await onSwitchChain())) return

      try {
        if (!walletClient) return zeroAddress

        return sdk.markets.write.modifyPosition({
          ...args,
          address: address ?? zeroAddress,
          marketAddress: productAddress,
          marketSnapshots,
          marketOracles,
        })

        /* console.log('Antes de build: ', productAddress)
        console.log('Antes args: ', args)
        const tx = await sdk.markets.build.modifyPosition({
          ...args,
          address: address ?? zeroAddress,
          marketAddress: productAddress,
          marketSnapshots,
          marketOracles,
        })
        // walletClient?.sendTransaction(tx)

        /* const req = await walletClient.prepareTransactionRequest(tx)
        console.log('req: ', req)
        return zeroAddress; */
      } catch (err: any) {
        console.log('Tx Error: ', err)
        onError?.(err)
      }
    },
  }
}

export const useCancelOrder = () => {
  const copy = useMarketTransactionCopy()
  const sdk = usePerennialSDKContext()
  const chainId = usePerpetualsChainId()
  const { onSwitchChain } = useChainActions()

  const { address } = useAddress()
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient({ chainId })

  const queryClient = useQueryClient()
  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => ['openOrders'].includes(queryKey.at(0) as string) && queryKey.includes(chainId),
      }),
    [queryClient, chainId],
  )

  const onCancelOrder = async (orderDetails: { market: Address, nonce: bigint }[]) => {
    if (!address || !chainId || !walletClient) {
      return
    }
    if (!(await onSwitchChain())) return

    try {
      const hash = await sdk.markets.write.cancelOrder({ orderDetails: orderDetails })

      publicClient
        ?.waitForTransactionReceipt({ hash })
        .then(() => refresh())
        .catch(() => null)
      // Refresh after a timeout to catch missed events
      setTimeout(() => refresh(), 15000)
      return hash
    } catch (err: any) {
      if (err.details !== metamaskTxRejectedError) {
        errorNotification(copy.errorCancelingOrder)
      }
      console.error(err)
      throw new Error(err)
    }
  }

  return onCancelOrder
}

const useMarketTransactionCopy = () => {
  const { t } = useTranslation()
  return {
    approveUSDC: t("notification.approved", { token: "USDC" }),
    errorCancelingOrder: t("notification.error-canceling-order"),
  }
}

