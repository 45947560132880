import React from "react"
import { useTranslation } from "react-i18next"

import Exchange from "../Exchange"
import { PriceChartWrapper, TradingViewWidget } from "../charts"
import { Positions } from "../positions"
import { PriceBox } from "../common"
import TabsChart from "../../../components/TabsChart"
import {
  useActiveProvider,
  useUserProductSnapshots,
} from "../../hooks"
import { OpenPositionType } from "../../utils/types"
import { CustomTooltip } from "../../../components/common"
import PLInterfaceToggle from "../../../components/common/PLInterfaceToggle"

type props = {
  currentInterface: number;
  // setCurrentInterface: (value: number) => void;
}

export const ProvidePro = ({ currentInterface }: props) => {
  const { t } = useTranslation()
  const [tabState, setTabState] = React.useState("market");
  const { userAccount } = useActiveProvider();
  const { data: userSnapshots, isLoading } = useUserProductSnapshots(userAccount);

  return(
    <>
      <TabsChart onTabChange={setTabState} />
      <div className="trade-module">
        <div className="version-chooser">
          <CustomTooltip
            id="tl-pl-interface"
            msg={t("tooltip.pro-interface")}
          >
            <PLInterfaceToggle currentInterface={currentInterface} />            
          </CustomTooltip>  
        </div> 
        <PriceBox showMarketCap={true} />
        <div className="trade-top">
          <div className="trade-left">
            {tabState === "market" ?  <TradingViewWidget/> : <PriceChartWrapper />}
          </div>
          <div className="trade-right">
            <Exchange isTaker={false} />
          </div>
        </div>
      </div>
      <div className="trade-bottom">
        <Positions
          userSnapshots={userSnapshots}
          isLoadingPositions={isLoading}
          positionType={OpenPositionType.Maker}
        />
      </div>
    </>
  );
}
