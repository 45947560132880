import React, { useContext, useMemo, useState } from 'react'
import { Button, ButtonGroup, Spinner, ToggleButton } from 'react-bootstrap/esm'
import { useTranslation } from 'react-i18next'
import { Big6Math, Big18Math, formatBig6, formatBig6USDPrice, VaultAccountSnapshot, VaultSnapshot } from "@perennial/sdk"

import { Balances, useOperators } from '../../../hooks/wallet'
import { CollateralInput } from "../../Trade/TradeForm/components"
import { LiquidityCapacity } from "./LiquidityCapacity"
import { VaultSelector } from "./VaultSelector"
import { useVaultTransactions } from '../../../hooks/vaults2'
import { useVaultFormValidators } from "../hooks"
import { geoLocationContext } from "../../../contexts"
import { ApproveButton, ApproveOperatorButton } from "../../common/TxButtons"
import { isNonNegativeNumber } from "../../../utils/utils"
import { NumericFormat } from "react-number-format"


const VaultForm = ({
  vaultSnapshot,
  vaultUserSnapshot,
  balances,
}: {
  vaultSnapshot: VaultSnapshot
  vaultUserSnapshot?: VaultAccountSnapshot
  balances: Balances
}) => {
  const { t } = useTranslation()
  const provideActions = [
    { name: t("deposit"), value: 0 },
    { name: t("withdraw"), value: 1 },
  ]
  const currentGeolocation = useContext(geoLocationContext)
  const [currentAction, setCurrentAction] = useState(provideActions[0].value)
  const [updatingVault, setUpdatingVault] = useState(false)
  const [currentAmount, setCurrentAmount] = useState("0")
  const [currentAmountBI, setCurrentAmountBI] = useState(0n)
  const [amountError, setAmountError] = useState("")
  const { data: operatorData } = useOperators()
  const marketFactoryApproved = operatorData && operatorData.vaultFactoryApproved
  const {
    vault: vaultAddress,
    totalAssets,
    parameter: { cap: maxCollateral },
    totalSettlementFee
  } = vaultSnapshot
  const { onDeposit, onRedeem, onClaim } = useVaultTransactions({ vaultType: vaultSnapshot.vaultType, vaultAddress })

  const { usdcBalance, usdcAllowance } = useMemo(() => {
    let usdcBalance = 0n
    let usdcAllowance = 0n
    if (balances) {
      usdcBalance = balances.usdc
      usdcAllowance = balances.usdcAllowance
    }
    return {
      usdcBalance,
      usdcAllowance
    }
  }, [balances])

  const { currentBalance, pendingRedemption, claimableAssets } = useMemo(() => {
    let pendingRedemption = 0n
    let claimableAssets = 0n
    let currentBalance = 0n
    if (vaultUserSnapshot) {
      currentBalance = vaultUserSnapshot.assets
      pendingRedemption = vaultUserSnapshot.accountData.redemption
      claimableAssets = vaultUserSnapshot.accountData.assets
      // currentBalance = claimableAssets - vaultSnapshot.totalSettlementFee
    }

    return {
      currentBalance,
      pendingRedemption,
      claimableAssets
    }
  }, [vaultUserSnapshot])

  const positionUpdating = Boolean(
    vaultUserSnapshot &&
      (!Big18Math.isZero(vaultUserSnapshot.accountData.deposit) ||
        !Big18Math.isZero(vaultUserSnapshot.accountData.redemption)),
  )
  const hasClaimable = !Big18Math.isZero(claimableAssets)
  const hasPendingRedemptions = !Big18Math.isZero(pendingRedemption)
  const showClaimOption = vaultUserSnapshot && (hasClaimable || hasPendingRedemptions)

  const vaultValidator = useVaultFormValidators({
    isDeposit: currentAction === 0,
    usdcBalance,
    vaultAssets: vaultUserSnapshot?.assets ?? 0n
  })
  
  const onChangeAmount = (value: string) => {
    if (isNonNegativeNumber(value) || value.trim() === "") {
      setCurrentAmount(value)
      const valid = vaultValidator.max(value)      
      setAmountError(valid.error || "")
      if (valid.isValid) {
        setCurrentAmountBI(Big6Math.fromFloatString(value))
      } else {
        setCurrentAmountBI(0n)
      }
    }
  }

  const onSetMaxAmount = () => {
    if (currentAction === 0) {
      onChangeAmount(formatBig6(usdcBalance, { numSigFigs: 6 }))
    } else {
      onChangeAmount(formatBig6(vaultUserSnapshot?.assets, { numSigFigs: 6 }))
    }
  }

  const showButton = () => {
    if (currentAction === 0) {
      return usdcAllowance >= currentAmountBI && marketFactoryApproved
    }
    return !showClaimOption
  }

  const onButtonClick = async () => {
    setUpdatingVault(true)
    if (currentAction === 0) {
      await onDeposit(currentAmountBI)
    } else {
      if (showClaimOption) {
        await onClaim()
      } else {
        await onRedeem(currentAmountBI, { assets: true, max: false })
      }
    }
    setUpdatingVault(false)
    setCurrentAmount("0")
    setCurrentAmountBI(0n)
  }

  const RenderClaimAmount = () => (
    <div className="claim-container">
      <div className="claim-container-value">
        <h6>{t("claimable-amount")}:</h6>
        {!updatingVault && !positionUpdating ? (
          <NumericFormat
            className="balance-usd bold"
            value={formatBig6USDPrice(claimableAssets - totalSettlementFee)}
            displayType="text"
            thousandSeparator=","
            suffix=" USD"
            decimalScale={4}
          />
        ) : (
          <Spinner animation="border" variant="secondary" className="xs" />
        )}
      </div>      
      <Button
        className="btn btn-primary btn-block deposit-button"
        onClick={() => onButtonClick()}
        disabled={updatingVault || pendingRedemption !== 0n}
      >
        <div className="btn-spinner">
          {updatingVault && <Spinner animation="border" variant="secondary" className="small" />}
          {updatingVault ? t("claiming") : t("claim")}
        </div>
      </Button>
    </div>
  );

  return (
    <>
      <VaultSelector />
      <div className="options provide">
        <ButtonGroup>
          {provideActions.map((action, idx) => (
            <ToggleButton
              key={idx}
              id={`market-${idx}`}
              type="radio"
              name="radio"
              value={action.value}
              checked={currentAction === action.value}
              onChange={(e) => {
                setCurrentAction(parseInt(e.currentTarget.value))
                setCurrentAmount("0")
                setCurrentAmountBI(0n)
              }}
              disabled={action.value === 1 && !marketFactoryApproved}
            >
              {action.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
      <LiquidityCapacity liquidity={totalAssets} capacity={maxCollateral} />
      <div className="controls">
        {currentAction === 0 || (currentAction === 1 && !showClaimOption) ? (
          <>
            <CollateralInput
              controlId="provide-collateral-input-id"
              showBalance={true}
              value={currentAmount}
              title={t("collateral")}
              onChange={(value: string) => onChangeAmount(value)}
              onSetMaxAmount={onSetMaxAmount}
              maxCaption={currentAction === 0 ? t("balance") : t("max-available")}
              vaultWithdrawal={currentAction !== 0}
              deposited={currentBalance}
            />
            {amountError !== "" && (
              <span className="error">
                {amountError}
              </span>
            )}
            <div className="fees">
              <div className="fees-container">
                <div className="row-value">
                  <h6 className="bold margin-left">{t("settlement-fee")}:</h6>                 
                  <NumericFormat
                    className="balance-usd bold"
                    value={formatBig6USDPrice(
                      totalSettlementFee * (currentAction === 0 ? 1n : 2n),
                    )}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={4}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {RenderClaimAmount()}
          </>
        )}
      </div>
      {!currentGeolocation.isRestricted() && (
        <>
          {!marketFactoryApproved && (
            <ApproveOperatorButton isMarket={false} onHide={() => {}} />
          )}
          {currentAction === 0 && usdcAllowance < currentAmountBI && marketFactoryApproved && (
            <ApproveButton
              approvalType="vault"
              contractAddres={vaultAddress}
              currentAllowance={formatBig6(usdcAllowance)}
              amountToApprove={currentAmountBI}
              vaultType={vaultSnapshot.vaultType}
              onSuccess={() => {}}
            />
          )}
          {showButton() && (
            <Button
              className="btn btn-primary btn-block deposit-button"
              onClick={() => onButtonClick()}
              disabled={updatingVault || amountError !== ""}
            >
              <div className="btn-spinner">
                {updatingVault && <Spinner animation="border" variant="secondary" className="small" />}
                {provideActions[currentAction].name}
              </div>
            </Button>
          )}
        </>
      )}      
    </>    
  )
}

export default VaultForm
