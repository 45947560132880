import React from "react"
import { Stack, ToggleButtonGroup, ToggleButton } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"

import { useCryptexProductsContext } from "../../contexts";
import { CustomTooltip } from "./Tooltip";


type props = {
  currentInterface: number;
  // setCurrentInterface?: (value: number) => void;
}

const PLInterfaceToggle = ({ currentInterface }: props) => {
  const { t } = useTranslation()
  const cryptexProducts = useCryptexProductsContext()
  const provideVersions = [
    { name: t("vaults"), value: 0 },
    { name: t("individual"), value: 1 },
  ]

  return (
    <ToggleButtonGroup
      type="radio"
      name="pl-versions" defaultValue={currentInterface}
      className="pl-version-chooser"
    >
      {provideVersions.map((action, idx) => (
        <ToggleButton
          key={idx}
          id={`plint-${idx}`}
          type="radio"
          name="radio"
          value={action.value}
          checked={currentInterface === action.value}
          onChange={(e) => {
            cryptexProducts.setCurrentMakerType(parseInt(e.currentTarget.value))
            /* if (setCurrentInterface) {
              setCurrentInterface(parseInt(e.currentTarget.value))
            } */
        }}
        >
          {action.value === 0 ? (
            <>{action.name}</>
          ) : (
            <Stack direction="horizontal" className="align-items-center justify-content-center" >
              <CustomTooltip
                id="individual"
                msg={t("tooltip.individual")}
                // showIcon={true} iconSize={14}
                iconColor="#e1e0ec"  
              >   
                <span style={{ marginRight: "3px" }}>{action.name}</span>
              </CustomTooltip>    
            </Stack>      
          )} 
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );     
}

export default PLInterfaceToggle
