import React from "react"
import { Stack } from "react-bootstrap"
import { LifiForm } from "../common/lifi";


export const Spot = () => (    
  <Stack className="h-100 w-100 mt-4 spot">
    <LifiForm />
  </Stack>
);
