import React, { useContext, useMemo } from "react"
import { Tabs, Tab } from "react-bootstrap/esm"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"
import {
  useActiveProvider,
  useLiquidityRewards,
  useVaultUserSnapshot,
  useVaultAllowances,
} from "../hooks"
import { productContext } from "../states"
import { Provide, ProvidePro, Rewards } from "../components/liquidity"
import MobileProvidePro from "../components/mobile/MobileProvidePro"
import { TOKENS_SYMBOLS } from "../utils/constants"
import { vaultHasLPRewards } from "../utils/utils"
import { useCryptexProductsContext } from "../../contexts"


const PWLiquidity = () => {
  const { t } = useTranslation()
  const cryptexProducts = useCryptexProductsContext()
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 850px)" });
  const { chainId } = useActiveProvider();
  const product = useContext(productContext);
  const { data: rewardsInfo, mutate: mutateRewards } = useLiquidityRewards();
  const { data: allowances, mutate: mutateAllowances } = useVaultAllowances(product.vaultContract[chainId].address);
  const { data: userVaultSnapshot, isLoading: loadingUserData, mutate: mutateUserVault }
    = useVaultUserSnapshot(product.vaultContract[chainId].address);

  const defaultTabKey = useMemo(() => {
    let defaultTabKey = "provide";
    const currentLocation = window.location.pathname;

    if (currentLocation.includes(TOKENS_SYMBOLS.tcap) && currentLocation.includes("rewards")) {
      defaultTabKey = "rewards";
    }

    return defaultTabKey;
  }, []);

  const mutateData = () => {
    mutateAllowances();
    mutateRewards();
    mutateUserVault();
  };

  return (
    <div className={"liquidity ".concat(cryptexProducts.makerType === 1 ? "pro" : "")}>
      {cryptexProducts.makerType === 0 ? (
        <div className="liquidity-card">
          <div className="liquidity-card-header">
            <h3>Perpetual Liquidity Vaults</h3>
            <h6>
              {t("info-msg.provide-v1")}
            </h6>
          </div>
          <div className="liquidity-card-body">
            <Tabs
              defaultActiveKey={defaultTabKey}
              id="pl-tabs"
              className="mb-3"
            >
              <Tab eventKey="provide" title={t("provide")} className="tab-provide">
                <Provide
                  allowances={allowances}
                  rewardsInfo={rewardsInfo}
                  loadingUserData={loadingUserData}
                  userVaultSnapshot={userVaultSnapshot}
                  rewardsApr={0}
                  mutateData={mutateData}
                />
              </Tab>
              <Tab
                eventKey="rewards"
                title={`${t("staking")} 0% ${t("apr")})`}
                className="tab-rewards"
                disabled={!vaultHasLPRewards(chainId, product.vaultContract[chainId].address)}
              >
                <Rewards
                  allowances={allowances}
                  rewardsInfo={rewardsInfo}
                  userVaultSnapshot={userVaultSnapshot}
                  rewardsApr={0}
                  mutateData={mutateData}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        <>
          {isMobileOrTablet ? (
            <MobileProvidePro currentInterface={cryptexProducts.makerType} />
          ) : (
            <ProvidePro currentInterface={cryptexProducts.makerType} />
          )}
        </>
      )}
    </div>
  );
};

export default PWLiquidity;
