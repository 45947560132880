import React from 'react';
import { Accordion, Button, Dropdown, Stack } from 'react-bootstrap';
import { SupportedMarket } from '@perennial/sdk';

import { TimeFrames, TimeFramesMetadata } from './constants';
import { MarketMetadata } from '../../constants/markets';


const DefaultTimeFrames = [TimeFrames.OneMinute, TimeFrames.ThirtyMinutes, TimeFrames.OneHour];

export const Toolbar = ({
  currentMarket,
  currentTimeFrame,
  handleTimeFrameChange,
}: {
  currentMarket: SupportedMarket,
  currentTimeFrame: TimeFrames,
  handleTimeFrameChange: (timeFrame: TimeFrames) => void,
}) => { 
  const market = MarketMetadata[currentMarket];

  return (
    <Stack
      direction="horizontal"
      gap={2}
      className="justify-content-start px-3 py-1"
      style={{ backgroundColor: "#131722" }}
    >
      <Stack direction="horizontal" className="align-self-center pe-2">
        <h6 className="w-auto text-muted small">{market.symbol}</h6>
      </Stack>
      <span className="vertical-divisor" style={{ height: "26px" }} />
      <Stack direction='horizontal' gap={0}>
        {DefaultTimeFrames.map((timeFrame) => (
          <TimeFrameButton
            key={timeFrame}
            timeFrame={timeFrame}
            active={timeFrame === currentTimeFrame}
            onClick={() => handleTimeFrameChange(timeFrame)}
          />
        ))}
        <TimeFramesDropown currentTimeFrame={currentTimeFrame} handleItemChange={(tf) => handleTimeFrameChange(tf as TimeFrames)} />
      </Stack>
      <span className="vertical-divisor" style={{ height: "26px" }} />
    </Stack>
  );
}

const TimeFrameButton = ({
  timeFrame,
  active,
  onClick
}: {
  timeFrame: TimeFrames,
  active: boolean,
  onClick: (tm: TimeFrames) => void,
}) => (
  <Button 
    className={`btn-outline-primary px-2 text-decoration-none small ${active ? 'active' : ''}`}
    onClick={() => onClick(timeFrame)}
    style={{
      fontSize: '0.85rem',
    }}
  >
    {TimeFramesMetadata[timeFrame].shortLabel}
  </Button>
);


const ItemsBlock = ({ label, range }: { label: string, range: [number, number] }) => (
  <Accordion defaultActiveKey="0">
    <Accordion.Item eventKey="0">
      <Accordion.Header>{label}</Accordion.Header>
      <Accordion.Body>
        {Object.keys(TimeFramesMetadata).map((timeFrame) => {
          const tf = TimeFramesMetadata[timeFrame as TimeFrames]
          if (tf.disabled || !(tf.seconds >= range[0] && tf.seconds < range[1])) return <></>

          return (
            <Dropdown.Item key={timeFrame} eventKey={timeFrame}>
              {tf.label}
            </Dropdown.Item>
          )
        })}
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
);


export const TimeFramesDropown = ({ currentTimeFrame, handleItemChange } : { currentTimeFrame: TimeFrames, handleItemChange: (tf: string) => void }) => {
  const timeFramesMetadata = TimeFramesMetadata[currentTimeFrame];
  
  return (
    <Dropdown
      onSelect={(eventKey) => handleItemChange(eventKey || TimeFrames.OneMinute)}
      className="dropdown-timeframes  border-0"
    >
      <Dropdown.Toggle
        id="dropdown-tf"
        variant="secondary"
        className="border-0 px-1"
        style={{
          fontSize: '0.85rem',
        }}
      >
        <span className="text-purple">
          {!DefaultTimeFrames.includes(currentTimeFrame) ? timeFramesMetadata.shortLabel : ""}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ItemsBlock label="MINUTES" range={[0, 3600]} />
        <ItemsBlock label="HOURS" range={[3600, 18000]} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

