import { useQuery } from '@tanstack/react-query'
import { Address, zeroAddress } from 'viem'
import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'

import { useAddress, usePerpetualsChainId } from '../network'

export const useProtocolParameter = () => {
  const chainId = usePerpetualsChainId()
  const sdk = usePerennialSDKContext()
  return useQuery({
    queryKey: ['protocolParameter2', chainId],
    enabled: !!chainId,
    queryFn: async () => {
      return sdk.contracts.getMarketFactoryContract().read.parameter()
    },
  })
}

export const useMarketOracles2 = () => {
  const chainId = usePerpetualsChainId()
  const sdk = usePerennialSDKContext()
  return useQuery({
    queryKey: ['marketOracles2', chainId],
    queryFn: async () => {
      const marketOracles = await sdk.markets.read.marketOracles()
      return marketOracles
    },
  })
}

export const useMarketSnapshots2 = (addressOverride?: Address) => {
  const chainId = usePerpetualsChainId()
  const { data: marketOracles } = useMarketOracles2()
  const { address: address_ } = useAddress()
  const sdk = usePerennialSDKContext()
  const address = addressOverride ?? address_ ?? zeroAddress

  return useQuery({
    queryKey: ['marketSnapshots2', chainId, address],
    enabled: !!address && !!marketOracles,
    queryFn: async () => {
      const marketSnapshots = await sdk.markets.read.marketSnapshots({
        marketOracles,
        address,
      })

      return marketSnapshots
    },
  })
}
