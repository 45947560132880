import React, { useEffect, useRef, useState } from 'react';
import { createChart, ColorType, LastPriceAnimationMode, BarPrice } from 'lightweight-charts';

import { useIndexLastestsPrices, useIndexPrices } from '../../hooks/feeds';
import { notEmpty } from '../../utils/utils';
import { SupportedIndex } from '../../constants/indexes';


export const Chart = ({
  indexSymbol,
  logicalFromTimestamp,
  fromTimestamp
}: {
  indexSymbol: string,
  logicalFromTimestamp: number,
  fromTimestamp: number 
}) => {
  const chartContainerRef = useRef<any>();
  const chart = useRef<any>();
  const series = useRef<any>();
  const colors = {
    backgroundColor: "#171B26",
    lineColor: '#A440F2',
    textColor: "#e1e0ec",
    areaTopColor: "#a540f282",
    areaBottomColor: "#a540f246",
  }

  const [prices, setPrices] = useState<any[]>([]);
  const {
    data: pricesData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useIndexPrices(indexSymbol as SupportedIndex, fromTimestamp);
  const { data: lastPrices } = useIndexLastestsPrices(indexSymbol as SupportedIndex);

  useEffect(() => {
    if (!isLoading && !isFetchingNextPage)  { 
      const prices = pricesData?.pages
        .map((page: any) => page?.prices)
        .flat()
        .filter(notEmpty) ?? []

      if (hasNextPage) {
        fetchNextPage();
      } else {
        setPrices(prices.sort((a: any, b: any) => a.time - b.time));
      }
    }
  },
    // eslint-disable-next-line
    [isLoading, isFetchingNextPage, pricesData]
  )

  useEffect(
    () => {
      const handleResize = () => {
        if (chartContainerRef.current !== null) {
          chart.current.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
          });
        }
      };

      chart.current = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: colors.backgroundColor },
          textColor: colors.textColor,
        },
        grid: {
          vertLines: {
            color: '#36383A',
          },
          horzLines: {
            color: '#36383A',
          },
        },
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        timeScale: {
          timeVisible: true,
          secondsVisible: false,          
        },
        localization: {
          priceFormatter: (price: BarPrice) => {
            return price.toFixed(4);
          },
        },
        rightPriceScale: {
          ticksVisible: true,
        }
      });
      
      if (prices.length > 0) { 
        chart.current.timeScale().setVisibleLogicalRange({
          from: logicalFromTimestamp,
          to: prices[prices.length - 1].time,
        });
      } else {
        chart.current.timeScale().fitContent();
      }

      chart.current.timeScale().scrollToPosition(5);
      series.current = chart.current.addAreaSeries({
        lineColor: colors.lineColor,
        topColor: colors.areaTopColor,
        bottomColor: colors.areaBottomColor,
        lastPriceAnimation: LastPriceAnimationMode.Continuous,
      });
      series.current.setData(prices);
      series.current.applyOptions({
        priceFormat: {
          type: 'custom',
          minMove: 0.0001,
          formatter: (price: BarPrice) => price.toFixed(4),
        },
      });
      
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.current.remove();
      };
    },
    // eslint-disable-next-line
    [prices]
  );

  useEffect(() => {
    if (lastPrices && lastPrices.length > 0) {
      series.current.update({ time: lastPrices[0].time, value: lastPrices[0].value });
    }
  }, [lastPrices]);

  return (
    <div className="h-100" ref={chartContainerRef} />
  );
};
