import React from "react"
import { useTranslation } from "react-i18next"

import TradeWrapper from "../Trade"
import { Provide } from "./components"
import { useCryptexProductsContext } from "../../contexts"


const ProvideLiquidity = () => {
  const { t } = useTranslation()
  const cryptexProducts = useCryptexProductsContext()

  return (
    <div className={"liquidity ".concat(cryptexProducts.makerType === 1 ? "pro" : "")}>
      {cryptexProducts.makerType === 0 ? (
        <div className="liquidity-card">  
          <div className="liquidity-card-header">
            <h3>Smart Vaults</h3>
            <h6>{t("info-msg.provide")}</h6>
          </div>
          <div className="liquidity-card-body">
            <Provide />
          </div>
        </div>
      ) : (
        <TradeWrapper isProInterface={true} currentInterface={cryptexProducts.makerType} />
      )}
    </div>
  )
}

export default ProvideLiquidity
