import React from "react";
import ReactGA from "react-ga4";

import ProvideLiquidity from "../../components/provide";
import { VaultProvider, useCryptexProductsContext } from "../../contexts";
import PWLiquidity from "../../v1/pages/PWLiquidity";

const ProvidePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/vaults", title: "Smart Vaults" });
  const cryptexProducts = useCryptexProductsContext()
  
  return (
    <VaultProvider>
      {cryptexProducts.isPerpetuals() ? (
        <ProvideLiquidity />
      ) : (
        <PWLiquidity />
      )}
    </VaultProvider>
  )
};

export default ProvidePage
