import React, { useState } from 'react'
import { Button, Image, Spinner } from 'react-bootstrap/esm'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { Big18Math, Big6Math, formatBig6Percent, formatBig6USDPrice, PerennialVaultType, SupportedAsset, VaultAccountSnapshot, VaultSnapshot } from '@perennial/sdk'

import { useVault7dMarketData, useVaultPositionHistory } from '../../../hooks/vaults2'
import { useExposureAndFunding, usePnl } from '../hooks'
import { MarketMetadata } from "../../../constants/markets"
import { usePerpetualsChainId } from '../../../hooks/network'


export const VaultMetrics = ({
  vault,
  vaultAccountSnapshot,
}: {
  vault: VaultSnapshot
  vaultAccountSnapshot?: VaultAccountSnapshot
}) => {
  const { t } = useTranslation()
  const chainId = usePerpetualsChainId()
  const vaultMarketData = useVault7dMarketData()
  const { data: positionHistory } = useVaultPositionHistory()
  const pnl = usePnl({
    vault,
    vaultAccountSnapshot,
    vaultPositionHistory: positionHistory?.[vault.vaultType as PerennialVaultType]
  })
  const exposureData = useExposureAndFunding({
    chainId,
    vault,
    marketData: vaultMarketData.find((v) => v.data?.vault.vaultAddress === vault.vault)?.data?.marketData,
  })
  
  const positionAmount = vaultAccountSnapshot?.assets ?? 0n
  const hasClaimable = !Big18Math.isZero(vaultAccountSnapshot?.accountData.assets ?? 0n)
  const claimableAssets = (vaultAccountSnapshot?.accountData.assets ?? 0n) - (hasClaimable ? vault.totalSettlementFee : 0n)

  // const hasClaimable = !Big18Math.isZero(vaultAccountSnapshot?.accountData.assets ?? 0n)
  // const hasPendingRedemptions = !Big18Math.isZero(vaultAccountSnapshot?.accountData.redemption ?? 0n)
  const positionUpdating = Boolean(
    vaultAccountSnapshot &&
      (!Big18Math.isZero(vaultAccountSnapshot.accountData.deposit) ||
        !Big18Math.isZero(vaultAccountSnapshot.accountData.redemption)),
  )

  return (
    <>
      <div className="values-top">
        <div className="value-item">
          <h6 className="margin-left">{t("current-balance")}:</h6>
          <NumericFormat
            className="balance-usd bold"
            value={formatBig6USDPrice(positionAmount)}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            prefix="$"
          />
        </div>
        <div className="value-item">
          <h6 className="margin-left">{t("total-profit-loss")}:</h6>
          {!positionUpdating ? (
            <NumericFormat
              className={pnl >= 0 ? "number green" : "number red"}
              value={formatBig6USDPrice(pnl)}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              prefix="$"
            />
          ) : (
            <Spinner animation="border" variant="secondary" className="xs" />
          )}
        </div>
        <div className="value-item">
          <h6 className="margin-left">{t("claimable-amount")}:</h6>
          <NumericFormat
            className="balance-usd bold"
            value={formatBig6USDPrice(claimableAssets)}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            prefix="$"
          />
        </div>
      </div>
      <div className="values-bottom">
        <TotalApr
          tradingFeesApr={exposureData?.totalFeeAPR ?? 0n}
          fundingFeesAPR={exposureData?.totalFundingAPR ?? 0n}
        />
        <ExposureMetrics
          vaultType={vault.vaultType}
          exposure={exposureData?.exposure}
          isLong={exposureData?.isLongExposure}
          totalWeight={exposureData?.totalWeight}
          marketExposures={exposureData?.marketExposures}
        />
      </div>
    </>    
  )  
}


const TotalApr = ({ tradingFeesApr, fundingFeesAPR } : { tradingFeesApr: bigint, fundingFeesAPR: bigint }) => {
  const { t } = useTranslation()
  const [showAprDetail, setShowAprDetail] = useState(false)
  const totalApr = tradingFeesApr + fundingFeesAPR

  return (
      <div className="value-group">
        <div className="value-item total">
          <div className="value-item-desc">
            <h6>{t("apr")}</h6>
            <Button
              variant="secondary small underline"
              onClick={() => setShowAprDetail(!showAprDetail)}
            >
              {showAprDetail ? `(${t("show-less")})` : `(${t("detail")})`}
            </Button> 
            <h6>:</h6>
          </div>
          <span className="balance-usd bold">{formatBig6Percent(totalApr)}</span>
        </div>
        <div className={"value-group-detail ".concat(showAprDetail ? "show" : "hide")}>
          <div className="value-item">
            <h6>{t("annualized-funding-rate")}:</h6>
            <NumericFormat
              className="balance-usd bold"
              value={formatBig6Percent(fundingFeesAPR)}
              displayType="text"
              thousandSeparator=","
              suffix="%"
              decimalScale={4}
            />
          </div>
          <div className="value-item">
            <h6>{t("trading-fees")}:</h6>
            <NumericFormat
              className="balance-usd bold"
              value={formatBig6Percent(tradingFeesApr)}
              displayType="text"
              thousandSeparator=","
              suffix="%"
              decimalScale={4}
            />
          </div>
        </div>
      </div>
    )
}

const ExposureMetrics = ({
  vaultType,
  exposure,
  isLong,
  totalWeight,
  marketExposures,
}: {
  vaultType: string
  exposure?: number
  isLong?: boolean
  totalWeight?: bigint
  marketExposures?: { exposurePct: number; asset?: SupportedAsset; weight: bigint }[]
  }) => {
  const { t } = useTranslation()
  const [showExposureDetail, setShowExposureDetail] = useState(false)

  return (
    <div className="value-group">
      <div className="value-item total">
        <div className="value-item-desc">
          <h6>{t("vault-exposure")}</h6>
          <Button
            variant="secondary small underline"
            onClick={() => setShowExposureDetail(!showExposureDetail)}
          >
            {showExposureDetail ? `(${t("show-less")})` : `(${t("detail")})`}
          </Button> 
          <h6>:</h6>
        </div>  
        <NumericFormat
          className="balance-usd bold"
          value={exposure?.toFixed(4) || 0}
          displayType="text"
          thousandSeparator=","
          prefix={isLong ? `${t("long")} ` : `${t("short")} `}
          suffix="%"
          decimalScale={4}
        />
      </div>
      <div className={"value-group-detail ".concat(showExposureDetail ? "show" : "hide")}>
        {marketExposures?.map(({ exposurePct, asset, weight }) => {
          if (vaultType === PerennialVaultType.alpha && asset === SupportedAsset.btc) {
            return null
          }

          return (
            <div key={asset} className="value-item">
              <div className="flex-row">
                <Image className="margin-right" src={MarketMetadata[asset as SupportedAsset].icon} height={20} width={20} />
                <h6>{MarketMetadata[asset as SupportedAsset].name}</h6>
                {totalWeight && totalWeight > 0n ? (
                  <span className="ms-1 text-muted">
                    ({formatBig6Percent(Big6Math.div(weight, totalWeight))})
                  </span>
                ) : undefined}
              </div>
              <NumericFormat
                className="balance-usd bold"
                value={Math.abs(exposurePct).toFixed(4)}
                displayType="text"
                thousandSeparator=","
                prefix={exposurePct > 0 ? `${t("long")} ` : `${t("short")} `}
                suffix="%"
                decimalScale={4}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
