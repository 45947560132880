import { useMemo } from 'react'
import {
  Big6Math,
  Big18Math,
  calcLpExposure,
  calcSkew,
  calcTakerLiquidity,
  efficiency,
  formatBig6Percent,
  formatBig6USDPrice,
  formatBig18USDPrice,
  sum,
} from '@perennial/sdk'

import { useMarketContext } from '../../../contexts/marketContext'
import { useMarket24HrHighLow } from "../../../hooks/markets2"
import { useMarkets24hrData, useMarket7dData } from '../../../hooks/markets2/graph'
import { useLivePriceContext } from '../../../contexts/livePriceContext'


export const useFormattedMarketBarValues = () => {
  const livePrices = useLivePriceContext()
  const { selectedMarket: selectedMarket_, isMaker, selectedMarketSnapshot2, selectedMakerMarket } = useMarketContext()

  const selectedMarket = isMaker ? selectedMakerMarket : selectedMarket_
  const { data: priceData } = useMarket24HrHighLow(selectedMarket)
  const { data: dailyData } = useMarkets24hrData()
  const { data: weeklyData } = useMarket7dData()

  const totalVolume = useMemo(() => {
    if (!dailyData?.[selectedMarket]) return 0n
    return dailyData[selectedMarket].takerVolumes.reduce(
      (acc, cur) => acc + BigInt(cur.longNotional) + BigInt(cur.shortNotional),
      0n,
    )
  }, [dailyData, selectedMarket])

  const chainPrice = selectedMarketSnapshot2?.global?.latestPrice ?? 0n
  const currentPrice = livePrices[selectedMarket]?.price ?? chainPrice ?? 0n
  const untransformedPrice =
    livePrices[selectedMarket]?.untransformed ?? Big18Math.fromDecimals(chainPrice, Big6Math.FIXED_DECIMALS) ?? 0n
  const change = currentPrice - BigInt(priceData?.open ?? 0n)

  const nextLong = selectedMarketSnapshot2?.nextPosition?.long ?? 0n
  const nextShort = selectedMarketSnapshot2?.nextPosition?.short ?? 0n
  const longOpenInterest = Big6Math.mul(nextLong, currentPrice)
  const shortOpenInterest = Big6Math.mul(nextShort, currentPrice)

  const availableLiq = selectedMarketSnapshot2 ? calcTakerLiquidity(selectedMarketSnapshot2) : undefined
  const lpExposure = calcLpExposure(selectedMarketSnapshot2)
  const calculatedSkew = calcSkew(selectedMarketSnapshot2)
  const makerEfficiency = efficiency(
    selectedMarketSnapshot2?.nextPosition.maker ?? 0n,
    selectedMarketSnapshot2?.nextMajor ?? 0n,
  )

  return {
    price: formatBig6USDPrice(currentPrice),
    untransformedPrice: formatBig18USDPrice(untransformedPrice),
    change: !priceData ? '--' : formatBig6Percent(Big6Math.abs(Big6Math.div(change, priceData.open))),
    changeIsNegative: change < 0n,
    low: !priceData ? '--' : formatBig6USDPrice(Big6Math.min(currentPrice, priceData.low)),
    high: !priceData ? '--' : formatBig6USDPrice(Big6Math.max(currentPrice, priceData.high)),
    volume: formatBig6USDPrice(totalVolume, { compact: true }),
    openInterest: `${formatBig6USDPrice(longOpenInterest, {
      compact: true,
    })} / ${formatBig6USDPrice(shortOpenInterest, { compact: true })}`,
    availableLiquidity: `${formatBig6USDPrice(Big6Math.mul(availableLiq?.availableLongLiquidity ?? 0n, currentPrice), {
      compact: true,
    })} / ${formatBig6USDPrice(Big6Math.mul(availableLiq?.availableShortLiquidity ?? 0n, currentPrice), {
      compact: true,
    })}`,
    totalLiquidity: `${formatBig6USDPrice(Big6Math.mul(availableLiq?.totalLongLiquidity ?? 0n, currentPrice), {
      compact: true,
    })} / ${formatBig6USDPrice(Big6Math.mul(availableLiq?.totalShortLiquidity ?? 0n, currentPrice), {
      compact: true,
    })}`,
    lpExposurePct: lpExposure?.formattedLpExposure ?? '0.00%',
    lpExposure: lpExposure?.exposureSide ?? '--',
    volume7d: `${formatBig6USDPrice(
      sum(weeklyData?.[selectedMarket]?.takerVolumes.map(({ longNotional }) => longNotional) ?? []) +
        sum(weeklyData?.[selectedMarket]?.takerVolumes.map(({ shortNotional }) => shortNotional) ?? []),
      {
        compact: true,
      },
    )}`,
    skew: formatBig6Percent(calculatedSkew?.skew ?? 0n),
    longSkew: formatBig6Percent(calculatedSkew?.longSkew ?? 0n),
    shortSkew: formatBig6Percent(calculatedSkew?.shortSkew ?? 0n),
    efficiency: formatBig6Percent(makerEfficiency),
  }
}
