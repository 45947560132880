
export enum TimeFrames {
  OneMinute = "1m",
  TwoMinutes = "2m",
  ThreeMinutes = "3m",
  FiveMinutes = "5m",
  TenMinutes = "10m",
  FifteenMinutes = "15m",
  TwentyMinutes = "20m",
  ThirtyMinutes = "30m",
  FortyFiveMinutes = "45m",
  OneHour = "1h",
  TwoHours = "2h",
  ThreeHours = "3h",
  FourHours = "4h",
  FiveHours = "5h",
  OneDay = "1d",
  OneWeek = "1w",
  OneMonth = "1M",
}

export type TimeFramesType = {
  [timeframe in TimeFrames]: {
    label: string,
    shortLabel: string,
    seconds: number,
    disabled?: boolean,
  }
};

export const TimeFramesMetadata: TimeFramesType = {
  [TimeFrames.OneMinute]: {
    label: "1 minute",
    shortLabel: "1m",
    seconds: 60,
  },
  [TimeFrames.TwoMinutes]: {
    label: "2 minutes",
    shortLabel: "2m",
    seconds: 120,
  },
  [TimeFrames.ThreeMinutes]: {
    label: "3 minutes",
    shortLabel: "3m",
    seconds: 180,
  },
  [TimeFrames.FiveMinutes]: {
    label: "5 minutes",
    shortLabel: "5m",
    seconds: 300,
  },
  [TimeFrames.TenMinutes]: {
    label: "10 minutes",
    shortLabel: "10m",
    seconds: 600,
  },
  [TimeFrames.FifteenMinutes]: {
    label: "15 minutes",
    shortLabel: "15m",
    seconds: 900,
  },
  [TimeFrames.TwentyMinutes]: {
    label: "20 minutes",
    shortLabel: "20m",
    seconds: 1200,
  },
  [TimeFrames.ThirtyMinutes]: {
    label: "30 minutes",
    shortLabel: "30m",
    seconds: 1800,
  },
  [TimeFrames.FortyFiveMinutes]: {
    label: "45 minutes",
    shortLabel: "45m",
    seconds: 2700,
  },
  [TimeFrames.OneHour]: {
    label: "1 hour",
    shortLabel: "1h",
    seconds: 3600,
  },
  [TimeFrames.TwoHours]: {
    label: "2 hours",
    shortLabel: "2h",
    seconds: 7200,
  },
  [TimeFrames.ThreeHours]: {
    label: "3 hours",
    shortLabel: "3h",
    seconds: 10800,
  },
  [TimeFrames.FourHours]: {
    label: "4 hours",
    shortLabel: "4h",
    seconds: 14400,
  },
  [TimeFrames.FiveHours]: {
    label: "5 hours",
    shortLabel: "5h",
    seconds: 18000,
  },
  [TimeFrames.OneDay]: {
    label: "1 day",
    shortLabel: "1d",
    seconds: 86400,
  },
  [TimeFrames.OneWeek]: {
    label: "1 week",
    shortLabel: "1w",
    seconds: 7 * 86400,
    disabled: true,
  },
  [TimeFrames.OneMonth]: {
    label: "1 Month",
    shortLabel: "1M",
    seconds: 30 * 86400,
    disabled: true,
  },
};