import { SupportedMarket, addressToAsset, chainVaultsWithAddress } from '@perennial/sdk'
import { useQueries } from '@tanstack/react-query'
import { useBlockNumber } from 'wagmi'
import { Address } from 'viem'

import { useMarket7dData } from '../markets2'
import { usePerpetualsChainId } from '../network'
import { useVaultSnapshots2 } from './chain'


export type VaultMarketData = NonNullable<Awaited<ReturnType<typeof useVault7dMarketData>>[number]['data']>
export const useVault7dMarketData = () => {
  const chainId = usePerpetualsChainId()
  const { data: vaultSnapshots, isLoading: vaultSnapshotsLoading } = useVaultSnapshots2()
  const vaults = chainVaultsWithAddress(chainId)
  const { data: blockNumber } = useBlockNumber()
  const { data: market7dData } = useMarket7dData()

  return useQueries({
    queries: vaults.map((vault) => {
      return {
        queryKey: ['vault7dMarketData', chainId, vault.vaultAddress],
        enabled: !vaultSnapshotsLoading && !!vaultSnapshots?.vault?.[vault.vault] && !!blockNumber && !!market7dData,
        queryFn: async () => {
          const vaultSnapshot = vaultSnapshots?.vault[vault.vault]
          if (!vaultSnapshot || !blockNumber || !market7dData) return

          const marketData = vaultSnapshot.registrations.reduce((acc, registration) => {
            const asset = addressToAsset(chainId, registration.market)
            if (!asset || !market7dData[asset]) return acc
            acc[registration.market] = market7dData[asset]
            return acc
          }, {} as Record<Address, (typeof market7dData)[SupportedMarket]>)

          return { vault, marketData }
        },
      }
    }),
  })
}
