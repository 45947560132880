import React, { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { Route } from '@lifi/sdk';
import { LiFiWidget, WidgetConfig, useWidgetEvents, WidgetEvent } from '@lifi/widget'
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useQueryClient } from '@tanstack/react-query'

import { SupportedLanguage } from "../../../constants/localization";
import { usePerpetualsChainId } from "../../../hooks/network";
import { UsdcAddresses } from "../../../constants/contracts";
import { useNativeBalance } from "../../../hooks/wallet";


export const LifiForm = ({ toToken, toChain } : { toToken?: string, toChain?: number }) => {
  const { i18n } = useTranslation()
  const { refetchBalance } = useNativeBalance()
  const chainId = usePerpetualsChainId()
  const { openConnectModal } = useConnectModal();
  const widgetEvents = useWidgetEvents();
  const queryClient = useQueryClient()  
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en)

  const lifiConfig = useMemo((): WidgetConfig => {
    const lifiConfig: WidgetConfig = {
      appearance: 'dark',
      hiddenUI: ['appearance', 'history', 'poweredBy', 'walletMenu'],
      integrator: "cryptex-finance",
      // fee: 0.001,
      toChain,
      toToken,
      languages: {
        default: currentLanguage as "bn" | "de" | "en" | "es" | "fr" | "id" | "it" | "ko" | "pt" | "th" | "tr" | "uk" | "vi" | "zh" | undefined,
      },
      theme: {
        container: {
          border: 'none',
          borderRadius: '0px',
          padding: '0px',
        },
        palette: {
          primary: { main: '#A440F2' },
          secondary: { main: '#F5B5FF' },
          background: { default: "#0a0a0a", paper: "#0a0a0a" },
          text: { primary: "#f5f5f5", disabled: "#aaa9bf" }
        },
        shape: {
          borderRadius: 5,
          borderRadiusSecondary: 5,
        },
        typography: {
          fontFamily: 'Helvetica Neue',
        },
      },
      variant: "wide",
      walletConfig: {
        onConnect() {
          openConnectModal?.();
        },
      },
      chains: {
        deny: [9001],
      }
    }
    return lifiConfig
  },
    // eslint-disable-next-line
    [currentLanguage]
  )
  
  const refreshBalances = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['balances'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )  
    
  useEffect(() => {
    const onRouteExecutionCompleted = (route: Route) => {
      if (UsdcAddresses[chainId].toLowerCase() === route.toToken.address.toLowerCase()) {
        refreshBalances()
        refetchBalance();
      }
    };
    widgetEvents.on(WidgetEvent.RouteExecutionCompleted, onRouteExecutionCompleted);
    
    return () => widgetEvents.all.clear();
  },
    [chainId, refreshBalances, refetchBalance, widgetEvents]
  );  
    
  return (
    <>
      <LiFiWidget integrator="cryptex-finance" config={lifiConfig} />
    </>
  );
};
