import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addressToMarket,
  Big6Math,
  calcMakerExposure,
  calcMakerStats,
  sum as sumBI,
  SupportedChainId,
  VaultAccountSnapshot,
  VaultSnapshot,
} from '@perennial/sdk'

import { VaultMarketData, VaultPositionHistory } from '../../hooks/vaults2'


export const useExposureAndFunding = ({
  chainId,
  vault,
  marketData,
}: {
  chainId: SupportedChainId
  vault?: VaultSnapshot
  marketData?: VaultMarketData['marketData']
}) => {
  const exposureAndFunding = useMemo(() => {
    if (!vault) {
      return
    }

    const { registrations, marketSnapshots, marketVaultSnapshots } = vault
    const marketExposures = registrations.map((registration) => {
      const marketSnapshot = marketSnapshots.find((v) => v.marketAddress === registration.market)
      const marketVaultSnapshot = marketVaultSnapshots.find((v) => v.marketAddress === registration.market)
      const price = marketSnapshot?.global.latestPrice ?? 0n
      const vaultMakerPosition = marketVaultSnapshot?.nextPosition.maker ?? 0n

      const exposure = calcMakerExposure(
        vaultMakerPosition,
        marketSnapshot?.nextPosition.maker ?? 0n,
        marketSnapshot?.nextPosition.long ?? 0n,
        marketSnapshot?.nextPosition.short ?? 0n,
      )
      const usdExposure = Big6Math.mul(exposure, price)
      const assets = marketVaultSnapshot?.local.collateral ?? 0n

      const marketStats = marketData?.[registration.market]
      const makerStats = calcMakerStats({
        funding: marketStats?.makerAccumulation.funding ?? 0n,
        interest: marketStats?.makerAccumulation.interest ?? 0n,
        positionFee: marketStats?.makerAccumulation.positionFee ?? 0n,
        positionSize: marketVaultSnapshot?.nextPosition.maker ?? 0n,
        collateral: marketVaultSnapshot?.local.collateral ?? 0n,
      })

      return {
        asset: addressToMarket(chainId, registration.market),
        usdExposure,
        assets,
        exposurePct: assets > 0n ? Big6Math.toUnsafeFloat(Big6Math.div(usdExposure, assets)) * 100 : 0,
        weight: registration.weight,
        makerStats,
      }
    })

    const netUSDExposure = sumBI(marketExposures.map(({ usdExposure }) => usdExposure))
    const netExposurePct = Big6Math.toUnsafeFloat(Big6Math.div(netUSDExposure, vault.totalAssets)) * 100

    const totalFundingAPR = marketExposures.reduce(
      (acc, curr) => acc + Big6Math.mul(curr.makerStats.fundingAPR + curr.makerStats.interestAPR, curr.weight),
      0n,
    )
    const totalFeeAPR = marketExposures.reduce(
      (acc, curr) => acc + Big6Math.mul(curr.makerStats.positionFeeAPR, curr.weight),
      0n,
    )

    return {
      marketExposures,
      exposure: Math.abs(netExposurePct),
      isLongExposure: netExposurePct > 0n,
      totalFundingAPR,
      totalFeeAPR,
      totalWeight: registrations.reduce((acc, curr) => acc + curr.weight, 0n),
    }
  },
    // eslint-disable-next-line
    [vault, marketData]
  )

  return exposureAndFunding
}


export const usePnl = ({
  vault,
  vaultAccountSnapshot,
  vaultPositionHistory,
}: {
  vault?: VaultSnapshot
  vaultAccountSnapshot?: VaultAccountSnapshot
  vaultPositionHistory?: VaultPositionHistory
}) => {
  const pnl = useMemo(() => {
    if (!vault || !vaultAccountSnapshot || !vaultPositionHistory) {
      return 0n
    }

    const userNetDeposits = vaultPositionHistory
      ? vaultPositionHistory.currentPositionDeposits - vaultPositionHistory.currentPositionClaims
      : Big6Math.ZERO

    const _pnl =
      sumBI([
        vaultAccountSnapshot.assets,
        vaultAccountSnapshot.accountData.assets,
        vaultAccountSnapshot.accountData.deposit,
        vaultAccountSnapshot.redemptionAssets,
      ]) - userNetDeposits;

    return _pnl
  }, [vault, vaultAccountSnapshot, vaultPositionHistory])

  return pnl
}

export function useVaultFormValidators({
  isDeposit,
  usdcBalance,
  vaultAssets  
} : {
  isDeposit: boolean
  usdcBalance: bigint
  vaultAssets: bigint
}) {
  const { t } = useTranslation()
  const maxValidator = useMemo(() => {
    if (isDeposit) {
      return (value: string) => {
        const inputValue = Big6Math.fromFloatString(value)
        const balance = Big6Math.fromDecimals(usdcBalance, 6)
        if (inputValue > balance) {
          return { isValid: false, error: t("error.no-usdc") }
        }
        return { isValid: true }
      }
    } else {
      return (value: string) => {
        const inputValue = Big6Math.fromFloatString(value)
        if (inputValue > vaultAssets) {
          return { isValid: false, error: t("error.exceeds-withdrawal") }
        }
        return { isValid: true }
      }
    }
  },
    // eslint-disable-next-line
    [isDeposit, usdcBalance, vaultAssets]
  )

  return { max: maxValidator }
}
