import React, { useCallback, useMemo, useState } from 'react'
import { Button, Image, Spinner, Stack, Table } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Big6Math, formatBig6USDPrice } from '@perennial/sdk';

import { FormattedOpenOrder, getOrderTypeFromOrder } from "../hooks";
import { useMarketContext } from '../../../contexts';
import { getOpenOrderLabel, isOpenOrderValid } from '../../../utils/positionUtils';

import { MarketMetadata } from '../../../constants/markets';
import { OrderTypes, OrderTypesLabels } from '../constants';
import { capitalize } from '../../../utils/utils';
import { NumericFormat } from 'react-number-format';
import { Address } from 'viem';
import { useCancelOrder } from '../../../hooks/markets2';
import { useTransactionToasts } from '../../common';
import { UpdateOrder } from '../modals/UpdateOrder';
import { UpdatePosition } from '../modals/UpdatePosition';
import { useMediaQuery } from 'react-responsive';


export const OrdersList = ({ openOrders }: { openOrders: FormattedOpenOrder[] }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" })
  const onCancelOrder = useCancelOrder()
  const [editing, setEditing] = useState(false)
  const [currentOrder, setCurrentOrder] = useState<FormattedOpenOrder>()
  const [limitOrder, setLimitOrder] = useState(false)

  return (
    <>
      {openOrders.length > 0 && !isMobile && (
        <Table striped hover variant="dark" className="orders-table">
          <thead>
            <tr>
              <th>{t("market")}</th>
              <th>{t("order-type")}</th>
              <th className="right">{t("position-size")}</th>
              <th className="right">{t("current-price")}</th>
              <th className="right">{t("trigger-price")}</th>
              <th className="center col-status">{t("status")}</th>
              <th className="right" />
            </tr>
          </thead>
          <tbody>
            {openOrders.map((order, index) => {
              return (
                <OrderRow
                  key={"order-row-".concat(index.toString())}
                  order={order}
                  allOrders={openOrders}
                  setCurrentOrder={(order: FormattedOpenOrder, isLimitOrder: boolean) => {
                    setCurrentOrder(order)
                    setLimitOrder(isLimitOrder)
                    setEditing(true)
                  }}
                  onCancelOrder={onCancelOrder}
                />
              )
            })}
          </tbody>
        </Table>
      )}
      {openOrders.length > 0 && isMobile && (
        <>
          {openOrders.map((order, index) => {
            return (
              <OrderRow
                key={"order-row-".concat(index.toString())}
                order={order}
                allOrders={openOrders}
                setCurrentOrder={(order: FormattedOpenOrder, isLimitOrder: boolean) => {
                  setCurrentOrder(order)
                  setLimitOrder(isLimitOrder)
                  setEditing(true)
                }}
                onCancelOrder={onCancelOrder}
              />
            )
          })}
        </>
      )}
      {openOrders.length === 0 && (
        <div className="empty-positions">
          <h5 className="text-purple">
            {t("info-msg.no-orders")}
          </h5>
        </div>
      )}
      {editing && currentOrder && !limitOrder && (
        <UpdateOrder
          showModal={!!currentOrder}
          order={currentOrder}
          isLimit={false}
          onHide={() => {
            setCurrentOrder(undefined)
            setEditing(false)
          }}
        />
      )}
      {editing && currentOrder && limitOrder && (
        <UpdatePosition
          showModal={!!currentOrder}
          isMaker={false}
          isLimitOrder={true}
          order={currentOrder}
          onHide={() => {
            setCurrentOrder(undefined)
            setEditing(false)
          }}
        />
      )}
    </>  
  )
}

const OrderRow = ({
  order,
  allOrders,
  setCurrentOrder,
  onCancelOrder
}: {
  order: FormattedOpenOrder,
  allOrders: FormattedOpenOrder[],
  setCurrentOrder: (currentOrder: FormattedOpenOrder, isLimitOrder: boolean) => void,
  onCancelOrder: (orders: { market: Address, nonce: bigint }[]) => Promise<`0x${string}` | undefined>
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" })
  const { side, orderDelta, triggerPrice, market, marketAddress, nonce, orderDeltaNotional, type } = order
  const { isMaker, snapshots2 } = useMarketContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const { waitForTransactionAlert } = useTransactionToasts()
  const marketMetadata = MarketMetadata[market]

  const orderLabel = getOpenOrderLabel({
    orderDelta: Big6Math.fromFloatString(orderDelta),
    comparison: type,
    orderDirection: side,
    isMaker,
  })
  const orderType = getOrderTypeFromOrder(order)
  const userMarketSnapshot = snapshots2?.user?.[market]
  const marketSnapshot = snapshots2?.market?.[market]

  const { latestPrice } = useMemo(() => {
    let latestPrice = 0n
    if (marketSnapshot) {
      latestPrice = marketSnapshot.global.latestPrice
    }

    return { latestPrice }
  }, [marketSnapshot])

  const { isValid, limitOpens, hasOpenPosition } = useMemo(
    () =>
      isOpenOrderValid({
        allOrders,
        order: order,
        userMarketSnapshot: userMarketSnapshot,
        marketSnapshot,
      }),
    [allOrders, order, userMarketSnapshot, marketSnapshot],
  )

  let canEdit = (hasOpenPosition || limitOpens > 0) && isValid && !isCancelled
  if (orderType === OrderTypes.limit) {
    canEdit = !hasOpenPosition && isValid && !isCancelled
  }

  const onCancel = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setIsSubmitting(true)
      try {
        const hash = await onCancelOrder([{ market: marketAddress, nonce: nonce }])
        if (hash) {
          waitForTransactionAlert(
            hash,
            {
              successMsg: t("notification.order-cancelled"),
              onSuccess: () => {
                setIsSubmitting(false)
                setIsCancelled(true)
              },
              onError: () => {
                setIsSubmitting(false)
              }
            }
          )
        }
      } catch (err) {
        setIsSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [setIsSubmitting, onCancelOrder, marketAddress, nonce, waitForTransactionAlert, setIsCancelled],
  )

  return (
    <>
      {!isMobile ? (
        <tr>
          <td>
            <Stack direction="vertical" gap={2} className="market">
              <Image className="token-icon margin-right" src={marketMetadata.icon} width={30} height={30} />
              <div className="market-info">
                <h6>
                  {capitalize(side)}
                </h6>
                <span className="title">{marketMetadata.name}</span>
              </div>
            </Stack>
          </td>
          <td>
            <span className={orderLabel === 'stopLoss' ? 'text-purple' : 'text-green'}>
              {OrderTypesLabels[orderLabel]}
            </span>
          </td>
          <td className="right">
            <Stack direction="vertical">
              <NumericFormat
                className="number"
                value={orderDelta}
                displayType="text"
                thousandSeparator=","
                suffix={` ${marketMetadata.baseCurrency.toUpperCase()}`}
                decimalScale={4}
              />
              <span className="number small text-muted">{orderDeltaNotional}</span>
            </Stack>
          </td>
          <td className="right">
            <NumericFormat
              className="number"
              value={formatBig6USDPrice(latestPrice)}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={4}
            />
          </td>
          <td className="right">
            <span className='number'>{triggerPrice}</span>
          </td>
          <td className="center col-status">
            {isCancelled ? (
              <span className="text-red">{t("cancelled")}</span>
            ) : (
              <>
                {isValid ? (
                  <span className="text-green">{t("placed")}</span>
                ) : (
                  <span className="text-muted">{t("invalid")}</span>        
                ) }
              </>   
            )}
          </td>
          <td className="right">
            <Button
              variant="outline-primary"
              onClick={() => setCurrentOrder(order, orderType === OrderTypes.limit)}
              disabled={!canEdit}
            >
              {t("change")}
            </Button>
            {!isCancelled && (
              <Button
                variant="outline-primary"
                onClick={onCancel}
                disabled={isSubmitting}
              >
                <div className="btn-spinner">
                  {isSubmitting && <Spinner animation="border" variant="secondary" className="xs" />}
                  {isSubmitting ? t("cancelling") : t("cancel")}
                </div>
              </Button>
            )}
          </td>
        </tr>
      ) : (
        <div className="position-box">
          <div className="box-header">
            <div className="market">
              <Image src={marketMetadata.icon} width={30} height={30} />
              <div className="market-info">
                <div className="postion-direction">
                  <h6>{capitalize(side)}</h6>
                  <div className="status">
                    <h6>-</h6>
                    <span className={orderLabel === 'stopLoss' ? 'text-purple' : 'text-green'}>
                      {OrderTypesLabels[orderLabel]}
                    </span>
                  </div>
                </div>
                <span className="title">{marketMetadata.name}</span>
              </div>
            </div>
          </div>
          <div className="box-body">
            <div className="position-item">
              <div className="left">
                <NumericFormat
                  className="number"
                  value={orderDelta}
                  displayType="text"
                  thousandSeparator=","
                  suffix={` ${marketMetadata.baseCurrency.toUpperCase()}`}
                  decimalScale={4}
                />
                <h6 className="margin-right">{t("position-size")}</h6>
              </div>
              <div className="right">
                <span className="number text-muted">{orderDeltaNotional}</span>
                <h6 className="margin-right">{t("order-notional")}</h6>
              </div>
            </div>
            <div className="position-item">
              <div className="left">
                <NumericFormat
                  className="number"
                  value={formatBig6USDPrice(latestPrice)}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
                <h6 className="margin-right">{t("current-price")}</h6>
              </div>
              <div className="right">
                <span className='number'>{triggerPrice}</span>
                <h6 className="margin-right">{t("trigger-price")}</h6>
              </div>
            </div>
            <div className="position-item">
              <div className="left">
                {isCancelled ? (
                  <span className="text-red">{t("cancelled")}</span>
                ) : (
                  <>
                    {isValid ? (
                      <span className="text-green">{t("placed")}</span>
                    ) : (
                      <span className="text-muted">{t("invalid")}</span>
                    )}
                  </>   
                )}
                <h6 className="margin-right">{t("status")}</h6>
              </div>
              <div className="right" />
            </div>
          </div>
          <div className="box-footer">
            <Button
              variant="outline-primary"
              onClick={() => setCurrentOrder(order, orderType === OrderTypes.limit)}
              disabled={!canEdit}
            >
              {t("change")}
            </Button>
            {!isCancelled && (
                <Button
                  variant="outline-primary"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  <div className="btn-spinner">
                    {isSubmitting && <Spinner animation="border" variant="secondary" className="xs" />}
                    {isSubmitting ? t("cancelling") : t("cancel")}
                  </div>
                </Button>
            )}
          </div>  
        </div>  
      )}  
    </>  
  )
}